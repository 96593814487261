/**
* @license
*
* Font Family: Britney
* Designed by: Diana Ovezea & Sabina Chipară
* URL: https://www.fontshare.com/fonts/britney/britney
* © 2024 Indian Type Foundry
*
* Britney Light 
* Britney Regular 
* Britney Bold 
* Britney Ultra 
* Britney Variable (Variable font)

*
*/
@font-face {
  font-family: 'Britney-Light';
  src: url('../fonts/britney/Britney-Light.woff2') format('woff2'),
       url('../fonts/britney/Britney-Light.woff') format('woff'),
       url('../fonts/britney/Britney-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Britney-Regular';
  src: url('../fonts/britney/Britney-Regular.woff2') format('woff2'),
       url('../fonts/britney/Britney-Regular.woff') format('woff'),
       url('../fonts/britney/Britney-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Britney-Bold';
  src: url('../fonts/britney/Britney-Bold.woff2') format('woff2'),
       url('../fonts/britney/Britney-Bold.woff') format('woff'),
       url('../fonts/britney/Britney-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Britney-Ultra';
  src: url('../fonts/britney/Britney-Ultra.woff2') format('woff2'),
       url('../fonts/britney/Britney-Ultra.woff') format('woff'),
       url('../fonts/britney/Britney-Ultra.ttf') format('truetype');
  font-weight: 1000;
  font-display: swap;
  font-style: normal;
}
/**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 900.0;
*
* available axes:
'wght' (range from 300.0 to 900.0
*/
@font-face {
  font-family: 'Britney-Variable';
  src: url('../fonts/britney/Britney-Variable.woff2') format('woff2'),
       url('../fonts/britney/Britney-Variable.woff') format('woff'),
       url('../fonts/britney/Britney-Variable.ttf') format('truetype');
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

